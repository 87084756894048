import { Component, Input, forwardRef, EventEmitter, Output, ChangeDetectorRef, OnChanges, AfterViewInit, } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

type InputTypes = 'text' | 'password';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true,
    },
  ],
})
export class InputComponent implements ControlValueAccessor, OnChanges {

  constructor(private cdr: ChangeDetectorRef) { }
  @Input() type: InputTypes = 'text';
  @Input() id = '';
  @Input() label = '';
  @Input() mask = '0*';
  @Input() placeholder = '';
  @Input() class = '';
  @Output() textChange = new EventEmitter();

  file = '';

  isVisible = false;

  value: any;

  onTouch!: () => void;

  ngOnChanges() {
    this.setMask();
    this.cdr.detectChanges();
  }

  onChange: (val: any) => void = (val) => {
    this.textChange.emit(val);
  };

  writeValue(value: any) {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
  inputVisibility() {
    return this.isVisible ? 'text' : this.type;
  }

  changeVisibility(event: MouseEvent) {
    this.isVisible = !this.isVisible;
  }

  setMask() {
    if (this.id === 'container') {
      this.mask = 'SSSS-000.000-0';
      return;
    }

    if (this.id === 'notaFiscal') {
      this.mask = '0*';
      return;
    }
  }
}
