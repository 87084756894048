import { Component, OnInit } from '@angular/core';

import { ToastrService } from "ngx-toastr";

import { HttpService } from "src/app/service/http.service";

import { AllColumns, FilterTables, TableData } from '../../../interface/table';
import { ContainerArmApoioResource } from '../../../interface/controleDesovaArmazem';

import { AllColumnsArmazem, FilterTable } from '../controle-desova-armazem-apoio.template';

import { Message } from '../../../enum/message';
import { Color } from '../../../enum/color';

@Component({
  selector: 'app-containers-armazem-apoio',
  templateUrl: './containers-armazem-apoio.component.html',
  styleUrls: ['./containers-armazem-apoio.component.css']
})
export class ContainersArmazemApoioComponent implements OnInit {

  tableData: TableData;

  allColumns: AllColumns[] = AllColumnsArmazem;

  filterTables: FilterTables[] = FilterTable;

  loaded: boolean = false;

  err: boolean = false;

  noRecords: boolean = false;

  message: Message;

  constructor(private httpService: HttpService, private toastr: ToastrService) { }

  ngOnInit() {
    this.getContainerArmApoio();
  }

  getContainerArmApoio() {

    this.httpService.getContainerArmApoio().subscribe(

      (resposta: ContainerArmApoioResource[]) => {

        const res: ContainerArmApoioResource[] = resposta;

        if (res.length === 0) {
          this.message = Message.NO_RECORDS;
          this.noRecords = true;
          this.loaded = true;
          return;
        }

        this.setColorsRow(res);

        this.setFilter(res);

        res.map((vl: ContainerArmApoioResource) => {
          if (vl.diasFreetime < 0) {
            vl.diasFreetime = 0;
          }
        });


        this.tableData = {
          allColumns: this.allColumns,
          filterTables: this.filterTables,
          element: res,
          field: "filter"
        };
        this.loaded = true;
      },
      (error) => {
        console.log("Erro", error);
        this.toastr.error('Falha ao carregar os dados.');
        this.message = Message.ERROR;
        this.loaded = true;
        this.err = true;
      }
    );
  }

  setColorsRow(res: ContainerArmApoioResource[]) {
    res.map((r: ContainerArmApoioResource) => {
      if (r.diasFreetime <= -3) {
        return r.colorRow = Color.GREEN;
      }

      switch (r.diasFreetime) {
        case -2:
          r.colorRow = Color.YELLOW;
          break;
        case -1:
          r.colorRow = Color.ORANGE;
          break;
        default:
          r.colorRow = Color.RED;
          break;
      }
    })
  }

  setFilter(res: ContainerArmApoioResource[]) {
    res.map((r: ContainerArmApoioResource) => {
      if (r.diasFreetime <= 0) {
        return r.filter = 'dentro';
      }

      return r.filter = 'vencido'

    })
  }
}
