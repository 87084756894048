import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';


@Component({
  selector: 'app-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioComponent),
      multi: true,
    },
  ],
})
export class RadioComponent implements ControlValueAccessor {

  constructor() { }
  @Input() id = '';
  @Input() label = '';
  @Input() class = '';
  @Input() options: { label: string[], value: number } = undefined;
  @Output() onChanged = new EventEmitter();

  onChange: any = () => { };
  onTouch: any = () => { };
  val = ''; // value of the component
  set value(val) {
    this.val = val;
    this.onChange(val);
    this.onTouch(val);
    this.onChanged.emit(val)
  }

  // programmatically writing the value
  writeValue(value: any) {
    this.value = value;
  }
  // method to be triggered on UI change
  registerOnChange(fn: any) {
    this.onChange = fn;
  }
  // method to be triggered on component touch
  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

}
