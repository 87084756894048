import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import {
  MatPaginatorModule,
  MatTableModule,
  MatSortModule,
  MatCardModule,
  MatIconModule,
  MatProgressSpinnerModule,
  MatButtonModule
} from '@angular/material';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatTableExporterModule } from 'mat-table-exporter';

import {
  MultilevelMenuService,
  NgMaterialMultilevelMenuModule
} from 'ng-material-multilevel-menu';

import { NgSelectModule } from '@ng-select/ng-select';

import { NgxEchartsModule } from 'ngx-echarts';
import { SimpleModalModule } from 'ngx-simple-modal';

import { JwtModule } from '@auth0/angular-jwt';

import { isNullOrUndefined } from 'util';

import { UserTokenResource } from '../interface/userTokenResource';
import { ToastrModule } from 'ngx-toastr';

export function tokenGetter(_) {
  const user: UserTokenResource = JSON.parse(localStorage.getItem("usuario"));
  if (isNullOrUndefined(user)) {
    return undefined;
  }
  return user.token;
}

@NgModule({
  imports: [
    BrowserAnimationsModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter
      },
    }),
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DragDropModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    SimpleModalModule,
    MatSnackBarModule,
    NgSelectModule,
    MatPaginatorModule,
    MatTableModule,
    MatSortModule,
    MatCardModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatTableExporterModule,
    MatButtonModule,
    HttpClientModule,
    NgMaterialMultilevelMenuModule,
    NgxEchartsModule,
    DatePipe
  ],
  providers: [MultilevelMenuService],
})
export class SharedModule { }
