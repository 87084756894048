import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from 'src/app/guard/auth.guard';

import { LoginComponent } from './login.component';
import { RecoveryPasswordComponent } from '../recovery-password/recovery-password.component';
import { ResetPasswordComponent } from './../reset-password/reset-password.component';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { ControleCargaConsolidadaComponent } from '../controle-carga-consolidada/controle-carga-consolidada.component';
import { ContainersDepositadosPortoOrigemComponent } from '../containers-depositados-porto-origem/containers-depositados-porto-origem.component'
import { ChegadaNaviosComponent } from './../chegada-navios/chegada-navios.component';
import { ControleContainersPortoDestinoComponent } from './../controle-carga-costado/controle-containers-porto-destino/controle-containers-porto-destino.component';
import { ControleDemurrageArmazenamentoComponent } from './../controle-carga-costado/controle-demurrage-armazenamento/controle-demurrage-armazenamento.component';
import { ControleLiberacaoCargaComponent } from './../controle-carga-costado/controle-liberacao-carga/controle-liberacao-carga.component';
import { ControleCargaRetidaComponent } from './../controle-carga-costado/controle-carga-retida/controle-carga-retida.component';
import { ContainersDesovaComponent } from './../controle-desova-armazem-apoio/containers-desova/containers-desova.component';
import { ContainersArmazemApoioComponent } from '../controle-desova-armazem-apoio/containers-armazem-apoio/containers-armazem-apoio.component';
import { LeadTimeComponent } from '../lead-time/lead-time.component';
import { ConsultaIndividualNfContainerComponent } from './../consulta-individual-nf-container/consulta-individual-nf-container.component';
import { NotFoundComponent } from './../not-found/not-found.component';
import { RelatorioComponent } from './../relatorio/relatorio.component';
import { TerminalApoioComponent } from '../terminal-apoio/terminal-apoio.component';
import { DemurrageComponent } from './../demurrage/demurrage.component';
import { EntregasFeriadoComponent } from '../entregas-feriado/entregas-feriado.component';
import { EstadiasCarretaComponent } from '../estadias-carreta/estadias-carreta.component';
import { HandlingTerminalApoioComponent } from '../handling-terminal-apoio/handling-terminal-apoio.component';
import { PuxadaTerminalApoioComponent } from '../puxada-terminal-apoio/puxada-terminal-apoio.component';

const routes: Routes = [
    { path: 'login', component: LoginComponent },
    { path: 'recovery-password', component: RecoveryPasswordComponent },
    { path: 'reset-password', component: ResetPasswordComponent },
    { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
    { path: 'controle-carga-consolidada', component: ControleCargaConsolidadaComponent, canActivate: [AuthGuard] },
    { path: 'containers-depositados-porto-origem', component: ContainersDepositadosPortoOrigemComponent, canActivate: [AuthGuard] },
    { path: 'chegada-navios', component: ChegadaNaviosComponent, canActivate: [AuthGuard] },
    { path: 'controle-containers-porto-destino', component: ControleContainersPortoDestinoComponent, canActivate: [AuthGuard] },
    { path: 'controle-demurrage-armazenamento', component: ControleDemurrageArmazenamentoComponent, canActivate: [AuthGuard] },
    { path: 'controle-liberacao-carga', component: ControleLiberacaoCargaComponent, canActivate: [AuthGuard] },
    { path: 'controle-carga-retida', component: ControleCargaRetidaComponent, canActivate: [AuthGuard] },
    { path: 'controle-containers-desova', component: ContainersDesovaComponent, canActivate: [AuthGuard] },
    { path: 'controle-containers-armazem-apoio', component: ContainersArmazemApoioComponent, canActivate: [AuthGuard] },
    { path: 'lead-time', component: LeadTimeComponent, canActivate: [AuthGuard] },
    { path: 'consulta-individual-nf-container', component: ConsultaIndividualNfContainerComponent, canActivate: [AuthGuard] },
    { path: 'total-custo-extra', component: RelatorioComponent, canActivate: [AuthGuard] },
    { path: 'terminal-apoio', component: TerminalApoioComponent, canActivate: [AuthGuard] },
    { path: 'demurrage', component: DemurrageComponent, canActivate: [AuthGuard] },
    { path: 'entregas-feriado', component: EntregasFeriadoComponent, canActivate: [AuthGuard] },
    { path: 'estadias-carreta', component: EstadiasCarretaComponent, canActivate: [AuthGuard] },
    { path: 'handling-terminal-apoio', component: HandlingTerminalApoioComponent, canActivate: [AuthGuard] },
    { path: 'puxada-terminal-apoio', component: PuxadaTerminalApoioComponent, canActivate: [AuthGuard] },
    { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
    { path: '**', component: NotFoundComponent }
]

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class LoginRoutingModule { }
