import { AllColumns, FilterTables } from "../../interface/table";

import { Row } from "src/app/enum/row";


export const AllColumn: AllColumns[] = [
    { nome: 'Navio', display: 'navio', index: 0, format: Row.NULL },
    { nome: 'Armador', display: 'armador', index: 1, format: Row.NULL },
    { nome: 'Booking', display: 'booking', index: 2, format: Row.NULL },
    { nome: 'Qtde de Containers', display: 'qtdContainer', index: 3, format: Row.LINK },
    { nome: 'Dt Saída do Navio', display: 'dtSaida', index: 4, format: Row.DATE },
    { nome: 'Dt Previsão de Chegada', display: 'dtPrevisaoChegada', index: 5, format: Row.DATE },
    { nome: 'Dt Atracação', display: 'dtAtracacao', index: 6, format: Row.DATE },
];

export const FilterTable: FilterTables[] = [
    { id: 1, name: 'NAVIOS EM VIAGENS', value: '29' },
    { id: 2, name: 'NAVIOS ATRACADOS', value: '32' },
];

