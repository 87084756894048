import { FilterTables } from './../../interface/table';
import { AllColumns } from "../../interface/table";

import { Row } from "../../enum/row";

export const AllColumnsDesova: AllColumns[] = [
    { nome: 'Container', display: 'container', index: 0, format: Row.NULL },
    { nome: 'Navio', display: 'navio', index: 1, format: Row.NULL },
    { nome: 'Armador', display: 'armador', index: 2, format: Row.NULL },
    { nome: 'Status Atual', display: 'etapa', index: 3, format: Row.NULL },
    { nome: 'Dt Chegada do Veículo no Destino', display: 'dtChegadaVeiculo', index: 4, format: Row.DATE },
    { nome: 'Embarcador', display: 'remetente', index: 5, format: Row.NULL },
    { nome: 'Dt Liberação do Container Vazio pelo Cliente', display: 'dtLiberacao', index: 6, format: Row.DATE },
    { nome: 'Tempo Decorrido (Dias)', display: 'tempoDecorr', index: 7, format: Row.NULL },
    { nome: 'Dias Excedidos Freetime', display: 'diasFreetime', index: 8, format: Row.NULL },
];

export const AllColumnsArmazem: AllColumns[] = [
    { nome: 'Container', display: 'container', index: 0, format: Row.NULL },
    { nome: 'Navio', display: 'navio', index: 1, format: Row.NULL },
    { nome: 'Armador', display: 'armador', index: 2, format: Row.NULL },
    { nome: 'Qtde NFs', display: 'qtdNotas', index: 3, format: Row.LINK },
    { nome: 'Dt Chegada no Terminal de Apoio', display: 'dtChegada', index: 4, format: Row.DATE },
    { nome: 'Embarcador', display: 'remetente', index: 5, format: Row.NULL },
    { nome: 'Tempo Decorrido (Dias)', display: 'tempoDecorr', index: 6, format: Row.NULL },
    { nome: 'Dias Excedidos Freetime', display: 'diasFreetime', index: 7, format: Row.NULL },
];

export const FilterTable: FilterTables[] = [
    { id: 1, name: 'DENTRO DO FREETIME', value: 'dentro' },
    { id: 2, name: 'FREETIME VENCIDO', value: 'vencido' },
];
