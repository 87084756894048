import { AllColumns } from '../../interface/table';

import { Row } from 'src/app/enum/row';

export const AllColumn: AllColumns[] = [
    { nome: 'Container', display: 'container', index: 0, format: Row.NULL },
    { nome: 'Qtde de NFs', display: 'qtdNotas', index: 1, format: Row.LINK },
    { nome: 'Qtde Remetente', display: 'qtdRemetente', index: 2, format: Row.LINK },
    { nome: 'DT Atracação do Navio', display: 'dtAtracacao', index: 3, format: Row.DATE },
    { nome: 'Armador', display: 'armador', index: 4, format: Row.NULL },
    { nome: 'Vencimento Armazenagem Porto', display: 'dtVencArmazenagem', index: 5, format: Row.DATE },
    { nome: 'Dias Excedidos Armazenagem', display: 'diasExcedidosArmaz', index: 6, format: Row.NULL },
    { nome: 'Vencimento de Demurrage Porto', display: 'dtVencDemurrage', index: 7, format: Row.DATE },
    { nome: 'Dias Excedidos Demurrage', display: 'diasExcedidosDemurr', index: 8, format: Row.NULL }
];
