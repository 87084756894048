import * as XLSX from "xlsx";

export class TableUtil {
    static exportToExcel(arr: any[], name?: string) {
        var wb = XLSX.utils.book_new();
        var ws = XLSX.utils.json_to_sheet(arr);
        XLSX.utils.book_append_sheet(wb, ws);
        XLSX.writeFile(wb, 'tracking-bemol.xlsx');
    }
}
