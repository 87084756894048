import { Component, OnInit } from '@angular/core';

import { ToastrService } from 'ngx-toastr';

import { HttpService } from '../../service/http.service';

import { AllColumns, FilterTables, TableData } from '../../interface/table';
import { ContainerDepositadoOrigemResource } from '../../interface/containerDepositadoOrigemResource';

import { AllColumn } from './containers-depositados-porto-origem.template';

import { Message } from '../../enum/message';

@Component({
  selector: 'app-containers-depositados-porto-origem',
  templateUrl: './containers-depositados-porto-origem.component.html',
  styleUrls: ['./containers-depositados-porto-origem.component.css']
})
export class ContainersDepositadosPortoOrigemComponent implements OnInit {

  tableData: TableData;

  allColumns: AllColumns[] = AllColumn;

  loaded: boolean = false;

  err: boolean = false;

  noRecords: boolean = false;

  message: Message;

  constructor(private httpService: HttpService, private toastr: ToastrService ) { }

  ngOnInit() {
    this.getContainersDepositadosOrigem();
  }

  getContainersDepositadosOrigem() {

    this.httpService.getContainersDepositadosOrigem().subscribe(

      (resposta: ContainerDepositadoOrigemResource[]) => {

        const res: ContainerDepositadoOrigemResource[] = resposta;

        if (res.length === 0) {
          this.message = Message.NO_RECORDS;
          this.noRecords = true;
          this.loaded = true;
          return;
        }
    
        const arrayFilter = res.map((vl) => vl.origem).filter((value, index, array) => { return array.indexOf(value) === index; });

        let filterTables: FilterTables[] = [];

        arrayFilter.forEach((vl, index) => filterTables.push({ id: index + 1, name: vl, value: vl }));
        
        this.tableData = {
          allColumns: this.allColumns,
          filterTables: filterTables,
          element: res,
          field: "origem"
        };
        this.loaded = true;
      },
      (error) => {
        console.log("Erro", error);
        this.toastr.error('Falha ao carregar os dados.'); 
        this.message = Message.ERROR;
        this.loaded = true;
        this.err = true;
      }
    );
  }
}
