import { TableService } from 'src/app/service/table.service';
import { MatTableExporterModule } from 'mat-table-exporter';
import { SimpleModalModule } from 'ngx-simple-modal';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { HttpService } from './service/http.service';
import { AppRoutingModule } from './app-routing.module';
import { LoginModule } from './pages/login/login.module'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule, MatNativeDateModule, MatProgressSpinnerModule, MatSortModule } from '@angular/material';
import { NgSelectModule } from '@ng-select/ng-select';
import { AuthService } from './service/auth.service';
import { MatTableModule, MatPaginatorModule } from '@angular/material';
import { NgxMaskModule } from 'ngx-mask'
import { ToastrModule } from 'ngx-toastr';
import { ChartsModule } from 'ng2-charts';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    SimpleModalModule,
    HttpClientModule,
    AppRoutingModule,
    LoginModule,
    BrowserModule,
    BrowserAnimationsModule,
    DragDropModule,
    MatSelectModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      progressBar: true,
      progressAnimation: 'increasing'
    }),
    MatFormFieldModule,
    MatInputModule,
    SimpleModalModule,
    MatButtonModule,
    MatDatepickerModule,
    MatNativeDateModule,
    HttpClientModule,
    FormsModule,
    NgSelectModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatTableExporterModule,
    MatDialogModule,
    NgxMaskModule.forRoot({
      dropSpecialCharacters: false,
    }),
    ChartsModule
  ],

  providers: [HttpService, AuthService, TableService, {
    provide: LocationStrategy,
    useClass: HashLocationStrategy
  }],
  bootstrap: [AppComponent],
  entryComponents: [],

})
export class AppModule { }
