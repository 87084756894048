import { LeadTimeResultResource } from './../interface/leadTimeResultResource';
import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';

import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { GraficoResource } from "../interface/graficoResource";
import { environment } from 'src/environments/environment';

import { User } from "../interface/user";

import { UserTokenResource } from "../interface/userTokenResource";

import { PasswordRecoveryResource } from '../interface/passwordRecoveryResource';
import { ChangePasswordResource } from '../interface/changePasswordResource';
import { ControleCargaConsolidadaResource } from "../interface/controleCargaConsolidadaResource";
import { ContainerDepositadoOrigemResource } from '../interface/containerDepositadoOrigemResource';
import { ChegadaNaviosResource } from 'src/app/interface/chegadaNaviosResource';
import { NotaFiscalContainersResource } from '../interface/notaFiscalContainersResource';
import { ContainersNaviosResource } from "../interface/containersNaviosResource";
import { RemetenteContainersResource } from "../interface/remetenteContainersResource";
import { CargaCostadoResource } from "../interface/cargaCostadoResource";
import { ContainerArmApoioResource, ContainerDesovaResource } from '../interface/controleDesovaArmazem';
import { ConsultaIndivContainerResource, ConsultaIndivNfResource } from '../interface/consultaIndividual';
import { RelatorioResource } from '../interface/RelatorioResouce';
import { RelatorioGeralResource } from '../interface/RelatorioGeralResource';
import { Moment } from 'moment';

@Injectable()
export class HttpService {
    private readonly urlHomologacao = `${environment.apiUrl}/api`;

    id: number;
    private token: string = '';
    private loggedIn: boolean = false;

    constructor(public httpClient: HttpClient) { }

    // tslint:disable-next-line: variable-name
    private _refreshNeeded$ = new Subject<void>();

    get refreshNeeded$() {
        return this._refreshNeeded$;
    }

    public get isLoggedIn(): boolean {
        return this.isLoggedIn;
    }

    public flushToken(): void {
        delete this.token;
    }

    public setLoggedIn(value: boolean, token?: string) {
        this.token = token;
        this.loggedIn = value;
    }

    /********************************************* POST **************************************************/

    postUsuario(user: User) {
        return this.httpClient.post<UserTokenResource>(`${this.urlHomologacao}/login`, user)
            .pipe(
                tap(() => {
                    this._refreshNeeded$.next();
                })
            );
    }

    postPasswordRecovery(passwordRecovery: PasswordRecoveryResource): Observable<any> {
        return this.httpClient.post<any>(`${this.urlHomologacao}/recovery`, passwordRecovery)
            .pipe(
                tap(() => {
                    this._refreshNeeded$.next();
                })
            );
    }

    postPasswordReset(newPassword: ChangePasswordResource): Observable<any> {
        return this.httpClient.post<any>(`${this.urlHomologacao}/recovery/reset`, newPassword)
            .pipe(
                tap(() => {
                    this._refreshNeeded$.next();
                })
            );
    }

    /********************************************* GET **************************************************/

    /*Retorna Dados Gráfico*/
    getDataChart(): Observable<GraficoResource> {
        const endpoint = '/grafico';
        return this.httpClient.get<GraficoResource>(this.urlHomologacao + endpoint);
    }

    /*Retorna Controle Carga Consolidada*/
    getControleCargaConsolidada(): Observable<ControleCargaConsolidadaResource[]> {
        const endpoint = '/container/carga-consol';
        return this.httpClient.get<ControleCargaConsolidadaResource[]>(this.urlHomologacao + endpoint);
    }

    /*Retorna Containers Depositados no Porto de Origem*/
    getContainersDepositadosOrigem(): Observable<ContainerDepositadoOrigemResource[]> {
        const endpoint = '/container/contdepo-origem';
        return this.httpClient.get<ContainerDepositadoOrigemResource[]>(this.urlHomologacao + endpoint);
    }

    /*Retorna Chegada dos Navios*/
    getChegadaNavios(): Observable<ChegadaNaviosResource[]> {
        const endpoint = '/navios/chegada-navios';
        return this.httpClient.get<ChegadaNaviosResource[]>(this.urlHomologacao + endpoint);
    }

    getRelatorio(begin: string, end: string): Observable<RelatorioResource[]> {
      const endpoint = '/relatorio/extras';
      return this.httpClient.get<RelatorioResource[]>(`${this.urlHomologacao}${endpoint}?begin=${begin}&end=${end}`);
    }

    getRelatorioDetalhes(date: string, titulo: string): Observable<RelatorioGeralResource[]> {
      const endpoint = '/relatorio/detalhes';
      return this.httpClient.get<RelatorioGeralResource[]>(`${this.urlHomologacao}${endpoint}?date=${date}&titulo=${titulo}`);
    }

    getFileRelatorio(begin: string, end: string): Observable<any> {
      const endpoint = "/relatorio/extras/export";
      return this.httpClient.get<any>(`${this.urlHomologacao}${endpoint}?begin=${begin}&end=${end}`, { observe: 'body', responseType: 'blob' as 'json' });
    }

    getTerminalApoio(begin: string, end: string): Observable<RelatorioResource[]> {
      const endpoint = '/relatorio/terminal-apoio';
      return this.httpClient.get<RelatorioResource[]>(`${this.urlHomologacao}${endpoint}?begin=${begin}&end=${end}`);
    }

    getFileRelatorioTerminalApoio(begin: string, end: string): Observable<any> {
      const endpoint = "/relatorio/terminal-apoio/export";
      return this.httpClient.get<any>(`${this.urlHomologacao}${endpoint}?begin=${begin}&end=${end}`, { observe: 'body', responseType: 'blob' as 'json' });
    }

    getDemurrage(begin: string, end: string): Observable<RelatorioResource[]> {
        const endpoint = '/relatorio/demurrage';
        return this.httpClient.get<RelatorioResource[]>(`${this.urlHomologacao}${endpoint}?begin=${begin}&end=${end}`);
    }

    getFileRelatorioDemurrage(begin: string, end: string): Observable<any> {
      const endpoint = "/relatorio/demurrage/export";
      return this.httpClient.get<any>(`${this.urlHomologacao}${endpoint}?begin=${begin}&end=${end}`, { observe: 'body', responseType: 'blob' as 'json' });
    }

    getEntregasFeriado(begin: string, end: string): Observable<RelatorioResource[]> {
        const endpoint = '/relatorio/entregas-feriado';
        return this.httpClient.get<RelatorioResource[]>(`${this.urlHomologacao}${endpoint}?begin=${begin}&end=${end}`);
    }

    getFileRelatorioEntregasFeriado(begin: string, end: string): Observable<any> {
      const endpoint = "/relatorio/entregas-feriado/export";
      return this.httpClient.get<any>(`${this.urlHomologacao}${endpoint}?begin=${begin}&end=${end}`, { observe: 'body', responseType: 'blob' as 'json' });
    }

    getEstadiaCarreta(begin: string, end: string): Observable<RelatorioResource[]> {
        const endpoint = '/relatorio/estadia-carretas';
        return this.httpClient.get<RelatorioResource[]>(`${this.urlHomologacao}${endpoint}?begin=${begin}&end=${end}`);
    }

    getFileRelatoriosEstadiaCarreta(begin: string, end: string): Observable<any> {
      const endpoint = "/relatorio/estadia-carretas/export";
      return this.httpClient.get<any>(`${this.urlHomologacao}${endpoint}?begin=${begin}&end=${end}`, { observe: 'body', responseType: 'blob' as 'json' });
    }

    getHandlingTerminalApoio(begin: string, end: string): Observable<RelatorioResource[]> {
        const endpoint = '/relatorio/handling-terminal-apoio';
        return this.httpClient.get<RelatorioResource[]>(`${this.urlHomologacao}${endpoint}?begin=${begin}&end=${end}`);
    }

    getFileRelatorioHandlingTerminalApoio(begin: string, end: string): Observable<any> {
      const endpoint = "/relatorio/handling-terminal-apoio/export";
      return this.httpClient.get<any>(`${this.urlHomologacao}${endpoint}?begin=${begin}&end=${end}`, { observe: 'body', responseType: 'blob' as 'json' });
    }

    getPuxadaTerminalApoio(begin: string, end: string): Observable<RelatorioResource[]> {
        const endpoint = '/relatorio/puxada-adicional-terminal-apoio';
        return this.httpClient.get<RelatorioResource[]>(`${this.urlHomologacao}${endpoint}?begin=${begin}&end=${end}`);
    }

    getFileRelatorioPuxadaTerminalApoio(begin: string, end: string): Observable<any> {
      const endpoint = "/relatorio/puxada-adicional-terminal-apoio/export";
      return this.httpClient.get<any>(`${this.urlHomologacao}${endpoint}?begin=${begin}&end=${end}`, { observe: 'body', responseType: 'blob' as 'json' });
    }

    /*Retorna Notas Fiscais*/
    getNotaFiscalContainers(container: string): Observable<NotaFiscalContainersResource[]> {
        const endpoint = '/notafiscal/nf-containers';
        return this.httpClient.get<NotaFiscalContainersResource[]>(`${this.urlHomologacao}${endpoint}?container=${container}`);
    }

    /* Retorna Remetente*/
    getRemetenteContainers(container: string): Observable<RemetenteContainersResource[]> {
        const endpoint = '/container/remetentes-container';
        return this.httpClient.get<RemetenteContainersResource[]>(`${this.urlHomologacao}${endpoint}?container=${container}`);
    }

    /*Retorna Containers*/
    getContainersNavios(navio: string, booking: string): Observable<ContainersNaviosResource[]> {
        const endpoint = '/navios/container-navios';
        return this.httpClient.get<ContainersNaviosResource[]>(`${this.urlHomologacao}${endpoint}?navio=${navio}&booking=${booking}`);
    }

    /*Retorna Carga Costado*/
    getCargaCostado(): Observable<CargaCostadoResource[]> {
        const endpoint = '/controlecarga/carga-costado';
        return this.httpClient.get<CargaCostadoResource[]>(this.urlHomologacao + endpoint);
    }

    /*Retorna Container Desova*/
    getContainerDesova(): Observable<ContainerDesovaResource[]> {
        const endpoint = '/container/desova';
        return this.httpClient.get<ContainerDesovaResource[]>(this.urlHomologacao + endpoint);
    }

    /*Retorna Container Armazém Apoio*/
    getContainerArmApoio(): Observable<ContainerArmApoioResource[]> {
        const endpoint = '/container/arm-apoio';
        return this.httpClient.get<ContainerArmApoioResource[]>(this.urlHomologacao + endpoint);
    }

    /*Retorna Container Lead Time*/
    getLeadTime(): Observable<LeadTimeResultResource[]> {
        const endpoint = '/notafiscal/lead-time';
        return this.httpClient.get<LeadTimeResultResource[]>(this.urlHomologacao + endpoint);
    }

    /*Retorna Consulta Individual*/
    getConsultaIndividual(value: string): Observable<ConsultaIndivContainerResource[] | ConsultaIndivNfResource[]> {
        const endpoint = '/container/consulta-indiv';
        return this.httpClient.get<ConsultaIndivContainerResource[] | ConsultaIndivNfResource[]>(`${this.urlHomologacao}${endpoint}/${value}`);
    }



}
