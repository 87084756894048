import { AllColumns } from './../../interface/table';

import { Row } from './../../enum/row';

export const AllColumn: AllColumns[] = [
  { nome: 'NF', display: 'nf', index: 0, format: Row.NULL },
  { nome: 'Série NF', display: 'serie', index: 1, format: Row.NULL },
  { nome: 'Emissão NF', display: 'emissaoNf', index: 2, format: Row.DATE },
  { nome: 'Porto Origem', display: 'portoOrigem', index: 3, format: Row.NULL },
  { nome: 'CNPJ Remetente', display: 'cnpjRemetente', index: 4, format: Row.NULL },
  { nome: 'Nome Remetente', display: 'nomeRemetente', index: 5, format: Row.NULL },
  { nome: 'Container', display: 'container', index: 6, format: Row.NULL },
  { nome: 'Dt Entrada Armazém Consolidador', display: 'dtInclusao', index: 7, format: Row.DATE },
  { nome: 'Status Atual', display: 'statusAtual', index: 8, format: Row.NULL },
  { nome: 'Tempo Decorrido (Dias)', display: 'tempoDecorrido', index: 9, format: Row.NULL }
];