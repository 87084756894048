import { Component, OnInit } from '@angular/core';

import { ToastrService } from 'ngx-toastr';

import { HttpService } from '../../../service/http.service';

import { AllColumns, FilterTables, TableData } from '../../../interface/table';
import { CargaCostadoResource } from '../../../interface/cargaCostadoResource';

import { AllColumn } from '../controle-carga-costado.template';

import { Message } from '../../../enum/message';
import { Color } from '../../../enum/color';

@Component({
  selector: 'app-controle-demurrage-armazenamento',
  templateUrl: './controle-demurrage-armazenamento.component.html',
  styleUrls: ['./controle-demurrage-armazenamento.component.css']
})
export class ControleDemurrageArmazenamentoComponent implements OnInit {

  tableData: TableData;

  allColumns: AllColumns[] = AllColumn;

  loaded: boolean = false;

  err: boolean = false;

  noRecords: boolean = false;

  message: Message;

  constructor(private httpService: HttpService, private toastr: ToastrService) { }

  ngOnInit() {
    this.getCargaCostado();
  }

  getCargaCostado() {
    
    this.httpService.getCargaCostado().subscribe(

      (resposta: CargaCostadoResource[]) => {

        const result: CargaCostadoResource[] = resposta;

        result.map((r: CargaCostadoResource) => {
          let value = this.getDay(r.diasExcedidosDemurr)
          r.severidade = value;
        });

        const res: CargaCostadoResource[] = result.filter((r: CargaCostadoResource) => (r.diasExcedidosArmaz >= -2));

        if (res.length === 0) {
          this.message = Message.NO_RECORDS;
          this.noRecords = true;
          this.loaded = true;
          return;
        }
       
        res.map((r: CargaCostadoResource) => {
          if (r.diasExcedidosArmaz < 0) {
            r.diasExcedidosArmaz = 0;
          }
          if (r.diasExcedidosDemurr < 0) {
            r.diasExcedidosDemurr = 0;
          }
        })

        this.setColorsRow(res);

        const arrayFilter = res.map((vl) => vl.severidade).filter((value, index, array) => {
          return array.indexOf(value) === index;
        });

        let filterTables: FilterTables[] = [];
        
        arrayFilter.forEach((vl, index) => filterTables.push({ id: index + 1, name: vl, value: vl }));
        
        this.tableData = {
          allColumns: this.allColumns,
          filterTables: filterTables,
          element: res,
          field: "severidade"
        };
        this.loaded = true;
      },
      (error) => {
        console.log("Erro", error);
        this.toastr.error('Falha ao carregar os dados.');
        this.message = Message.ERROR;
        this.loaded = true;
        this.err = true;
      }
    );
  }

  getDay(day: number): string {
    if (day >= 0) {
      return "INTOLERAVEL";
    }
    switch (day) {
      case -1:
        return "SUBSTANCIAL";
      case -2:
        return "MODERADO";
      default:
        return "TOLERAVEL";
    }
  }

  setColorsRow(res: CargaCostadoResource[]) {
    res.map((r: CargaCostadoResource) => {
      switch (r.severidade) {
        case 'INTOLERAVEL':
          r.colorRow = Color.RED;
          break;
        case 'SUBSTANCIAL':
          r.colorRow = Color.ORANGE;
          break;
        case 'MODERADO':
          r.colorRow = Color.YELLOW;
          break;
        default:
          r.colorRow = Color.GREEN;
          break;
      }
    });
  }
}
