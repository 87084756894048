import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatText'
})
export class FormatTextPipe implements PipeTransform {

  transform(value: any): any {
    return isNaN(value) ? value.toUpperCase() : value;
  }

}
