import { Row } from "src/app/enum/row";

export const AllColumn = [
  { nome: 'NF', display: 'numero', index: 0, format: Row.NULL },
  { nome: 'Série NF', display: 'serie', index: 1, format: Row.NULL },
  { nome: 'Emissão NF', display: 'dtEmissao', index: 2, format: Row.DATE },
  { nome: 'CNPJ Remetente', display: 'cnpjRemetente', index: 3, format: Row.NULL },
  { nome: 'Nome Remetente', display: 'remetente', index: 4, format: Row.NULL },
  { nome: 'Container', display: 'container', index: 5, format: Row.NULL },
  { nome: 'Porto Origem', display: 'portOrigem', index: 6, format: Row.NULL },
  { nome: 'Tipo Carga', display: 'tipoCarga', index: 7, format: Row.NULL },
  { nome: 'Armazém Consolidador', display: 'consol', index: 8, format: Row.NULL },
  { nome: 'Rodoviário Origem', display: 'origem', index: 9, format: Row.NULL },
  { nome: 'Navio', display: 'navio', index: 10, format: Row.NULL },
  { nome: 'Porto', display: 'porto', index: 11, format: Row.NULL },
  { nome: 'Terminal de Apoio', display: 'terminal', index: 12, format: Row.NULL },
  { nome: 'Total', display: 'total', index: 13, format: Row.NULL },
];
