import { EventEmitter, Injectable, Output } from "@angular/core";
import { MatDialog } from '@angular/material';

import { ToastrService } from 'ngx-toastr';

import { ModalComponent } from "../components/modal/modal.component";

import { HttpService } from './http.service';

import { TableData, AllColumns } from '../interface/table';
import { ContainersNaviosResource } from "../interface/containersNaviosResource";
import { NotaFiscalContainersResource } from "../interface/notaFiscalContainersResource";
import { RemetenteContainersResource } from "../interface/remetenteContainersResource";

import { Row } from "../enum/row";

@Injectable()
export class TableService {

    @Output() loadedModal = new EventEmitter<boolean>();

    tableData: TableData;

    constructor(
        private httpService: HttpService,
        private dialog: MatDialog,
        private toastr: ToastrService
    ) { }

    public getContainersNavios(event: any) {
        this.loadedModal.emit(true);
        this.httpService.getContainersNavios(event.navio, event.booking).subscribe(
            (resposta: ContainersNaviosResource[]) => {
                const res: ContainersNaviosResource[] = resposta;
                const allColumns: AllColumns[] = [
                    { nome: 'Container', display: 'container', index: 0, format: Row.NULL },
                    { nome: 'Porto Origem', display: 'origem', index: 1, format: Row.NULL },
                    { nome: 'Qtde NF', display: 'qtdNotas', index: 2, format: Row.LINK },
                    { nome: 'Qtde Remetente', display: 'qtdRemetente', index: 3, format: Row.LINK },
                ];
                const table = {
                    allColumns: allColumns,
                    element: res,
                };
                this.loadedModal.emit(false);
                this.dialog.open(ModalComponent, {
                    width: '80%',
                    height: '80%',
                    data: {
                        tableData: table,
                        element: event,
                        display: event.field
                    }
                });
            },
            (error) => {
                this.loadedModal.emit(false)
                this.toastr.error('Falha ao carregar os dados.'); 
                console.log("Erro", error);
            }
        );
    }

    public getNotaFiscalContainers(event: any) {
        this.loadedModal.emit(true);
        this.httpService.getNotaFiscalContainers(event.container).subscribe(
            (resposta: NotaFiscalContainersResource[]) => {
                const res: NotaFiscalContainersResource[] = resposta;
                const allColumns: AllColumns[] = [
                    { nome: 'NF', display: 'numero', index: 0, format: Row.NULL },
                    { nome: 'Série NF', display: 'serie', index: 1, format: Row.NULL },
                    { nome: 'Emissão NF', display: 'dtEmissao', index: 2, format: Row.DATE },
                    { nome: 'Cnpj Remetente', display: 'cnpj', index: 3, format: Row.NULL },
                    { nome: 'Nome Remetente', display: 'fantasia', index: 4, format: Row.NULL },
                    { nome: 'CT-e', display: 'cte', index: 5, format: Row.NULL },
                    { nome: 'Booking', display: 'booking', index: 5, format: Row.NULL },
                ];
                const table = {
                    allColumns: allColumns,
                    element: res,
                };
                this.loadedModal.emit(false);
                this.dialog.open(ModalComponent, {
                    width: '80%',
                    height: '80%',
                    data: {
                        tableData: table,
                        element: event,
                        display: event.field
                    }
                });
            },
            (error) => {
                this.loadedModal.emit(false);
                this.toastr.error('Falha ao carregar os dados.'); 
                console.log("Erro", error);
            }
        );
    }

    public getRemetenteContainers(event: any) {
        this.loadedModal.emit(true);
        this.httpService.getRemetenteContainers(event.container).subscribe(
            (resposta: RemetenteContainersResource[]) => {
                const res: RemetenteContainersResource[] = resposta;
                const allColumns: AllColumns[] = [
                    { nome: 'Cnpj Remetente', display: 'cnpjRemetente', index: 0, format: Row.NULL },
                    { nome: 'Nome Remetente', display: 'nomeRemetente', index: 1,format: Row.NULL },
                    { nome: 'QTDE NFs', display: 'nfRemetente', index: 2, format: Row.NULL },
                ];
                const table = {
                    allColumns: allColumns,
                    element: res,
                };
                this.loadedModal.emit(false);
                this.dialog.open(ModalComponent, {
                    width: '80%',
                    height: '80%',
                    data: {
                        tableData: table,
                        element: event,
                        display: event.field
                    }
                });
            },
            (error) => {
                this.loadedModal.emit(false);
                this.toastr.error('Falha ao carregar os dados.'); 
                console.log("Erro", error);
            }
        );
    }
}
