import { Component, OnInit } from "@angular/core";

import { ToastrService } from "ngx-toastr";

import { HttpService } from "../../service/http.service";

import { AllColumns, FilterTables, TableData } from './../../interface/table';
import { ControleCargaConsolidadaResource } from "../../interface/controleCargaConsolidadaResource";

import { AllColumn } from "./controle-carga-consolidada.template";

import { Message } from '../../enum/message';

import { Color } from "../../enum/color";

@Component({
  selector: 'app-controle-carga-consolidada',
  templateUrl: './controle-carga-consolidada.component.html',
  styleUrls: ['./controle-carga-consolidada.component.css']
})
export class ControleCargaConsolidadaComponent implements OnInit {

  tableData: TableData;

  allColumns: AllColumns[] = AllColumn;

  loaded: boolean = false;

  err: boolean = false;

  noRecords: boolean = false;

  message: Message;

  constructor(private httpService: HttpService, private toastr: ToastrService) { }

  ngOnInit() {
    this.getControleCargaConsolidada();
  }

  getControleCargaConsolidada() {

    this.httpService.getControleCargaConsolidada().subscribe(

      (resposta: ControleCargaConsolidadaResource[]) => {

        const res: ControleCargaConsolidadaResource[] = resposta;

        if (res.length === 0) {
          this.message = Message.NO_RECORDS;
          this.noRecords = true;
          this.loaded = true;
          return;
        }

        res.map((el: ControleCargaConsolidadaResource) => {
          switch (el.portoOrigem) {
            case "SANTOS / SP":
              el.portoOrigem = 'SBC';
              break;
            case "ITAJAI / SC":
              el.portoOrigem = 'ITJ';
              break;
            default:
              el.portoOrigem = el.portoOrigem;
              break;
          }
        });

        this.setColorsRow(res);

        const arrayFilter = res.map((vl: ControleCargaConsolidadaResource) => vl.statusAtual).filter((value, index, array) => { return array.indexOf(value) === index });

        let filterTables: FilterTables[] = [];

        arrayFilter.forEach((vl, index) => filterTables.push({ id: index + 1, name: vl, value: vl }));

        this.tableData = {
          allColumns: this.allColumns,
          filterTables: filterTables,
          element: res,
          field: "statusAtual"
        };
        this.loaded = true;
      },
      (error) => {
        console.log("Erro", error);
        this.toastr.error('Falha ao carregar os dados.');
        this.message = Message.ERROR;
        this.loaded = true;
        this.err = true;
      }
    );
  }

  setColorsRow(res: ControleCargaConsolidadaResource[]) {
    res.map((r: ControleCargaConsolidadaResource) => {
      if (r.statusAtual === 'SOBRA') {
        r.colorRow = Color.RED;
      }
    });
  }
}
