import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { JwtHelperService } from '@auth0/angular-jwt'

import { ToastrService } from 'ngx-toastr';

import { isNullOrUndefined } from 'util';

import { AuthService } from 'src/app/service/auth.service';

import { ChangePasswordResource } from '../../interface/changePasswordResource'

type InputTypes = 'text' | 'password';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  newPassword: ChangePasswordResource = <ChangePasswordResource>{}

  formReset: FormGroup;

  loaded: boolean = false;

  isVisible: boolean = false;

  type: InputTypes = 'password';

  get password(): FormControl { return this.formReset.get('password') as FormControl; }
  get passwordConfirmation(): FormControl { return this.formReset.get('passwordConfirmation') as FormControl; }

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private toastr: ToastrService,
    private jwtHelperService: JwtHelperService

  ) { }

  ngOnInit() {
    this.validateEmailAndToken()
  }

  validateEmailAndToken() {
    const emailParttern: any = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    const email = this.route.snapshot.queryParamMap.get('email');
    const token = this.route.snapshot.queryParamMap.get('token');

    if (this.authService.isAuthenticated()) {
      this.router.navigate(['/dashboard']);
      return
    }

    if(isNullOrUndefined(email) || isNullOrUndefined(token)){
      this.router.navigate(['/login']);
      return;
    }

    if (!emailParttern.test(email)) {
      this.router.navigate(['/login']);
      this.toastr.error('Email inválido');
      return;
    }

    if (this.jwtHelperService.isTokenExpired(token)) {
      this.router.navigate(['/recovery-password']);
      this.toastr.error('Faça uma nova solicitação de redefinição de senha', 'Token Expirado');
      return;
    }

    this.createForm();
  }

  createForm() {
    this.newPassword.email = this.route.snapshot.queryParamMap.get('email');
    this.newPassword.token = this.route.snapshot.queryParamMap.get('token');

    this.formReset = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(6)]],
      passwordConfirmation: ['', [Validators.required, Validators.minLength(6)]]
    }, { validator: ResetPasswordComponent.equalsTo });
  }

  static equalsTo(group: AbstractControl): { [key: string]: boolean } {
    const password = group.get('password');
    const passwordConfirmation = group.get('passwordConfirmation');

    if (!password || !passwordConfirmation) {
      return undefined;
    }

    if (password.value !== passwordConfirmation.value) {
      return { passwordsNotMatch: true };
    }
    return undefined;
  }

  submitForm() {
    this.loaded = true;
    this.newPassword.senha = this.password.value;
    this.authService.resetPassword(this.newPassword).subscribe(
      _ => {
        this.toastr.success('Senha alterada com sucesso!');
        this.router.navigate(['/login']);
        this.loaded = false;
      }, error => {
        this.toastr.error('Não foi possível alterar a senha');
        console.log('Erro no método submitForm: ', error);
        this.loaded = false;
      }
    );
  }

  onBack() {
    this.router.navigate(['/login'])
  }

  inputVisibility() {
    return this.isVisible ? 'text' : this.type;
  }

  changeVisibility(event: MouseEvent) {
    this.isVisible = !this.isVisible;
  }

}
