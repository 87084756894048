import { isNullOrUndefined } from 'util';
import { Component, OnInit } from "@angular/core";

import { ToastrService } from "ngx-toastr";

import { HttpService } from "../../service/http.service";

import { AllColumns, FilterTables, TableData } from '../../interface/table';
import { LeadTimeResultResource } from "../../interface/leadTimeResultResource";

import { AllColumn } from './lead-time.template';

import { Message } from '../../enum/message';

@Component({
  selector: 'app-lead-time',
  templateUrl: './lead-time.component.html',
  styleUrls: ['./lead-time.component.css']
})
export class LeadTimeComponent implements OnInit {

  tableData: TableData;

  allColumns: AllColumns[] = AllColumn;

  loaded: boolean = false;

  err: boolean = false;

  noRecords: boolean = false;

  message: Message;

  constructor(private httpService: HttpService, private toastr: ToastrService) { }

  ngOnInit() {
    this.getLeadTime();
  }

  getLeadTime() {
    this.httpService.getLeadTime().subscribe(

      (resposta: LeadTimeResultResource[]) => {

        const res: LeadTimeResultResource[] = resposta;

        if (res.length === 0) {
          this.message = Message.NO_RECORDS;
          this.noRecords = true;
          this.loaded = true;
          return;
        }

        res.map((vl: LeadTimeResultResource) => {
          if (isNullOrUndefined(vl.consol)) {
            vl.consol = 0;
          }

          if (isNullOrUndefined(vl.origem)) {
            vl.origem = 0;
          }

          if (isNullOrUndefined(vl.navio)) {
            vl.navio = 0;
          }

          if (isNullOrUndefined(vl.porto)) {
            vl.porto = 0;
          }

          if (isNullOrUndefined(vl.terminal)) {
            vl.terminal = 0;
          }

          if (isNullOrUndefined(vl.total)) {
            vl.total = 0;
          }
        });

        const arrayFilter = res.map((vl: LeadTimeResultResource) => vl.tipoCarga).filter((value, index, array) => { return array.indexOf(value) === index });

        let filterTables: FilterTables[] = [];

        arrayFilter.forEach((vl, index) => filterTables.push({ id: index + 1, name: vl, value: vl }));

        this.tableData = {
          allColumns: this.allColumns,
          filterTables: filterTables,
          element: res,
          field: "tipoCarga"
        };
        this.loaded = true;
      },
      (error) => {
        console.log("Erro", error);
        this.toastr.error('Falha ao carregar os dados.');
        this.message = Message.ERROR;
        this.loaded = true;
        this.err = true;
      }
    );
  }
}
