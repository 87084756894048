import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { ToastrService } from 'ngx-toastr';

import { HttpService } from '../../service/http.service';

import { ModalComponent } from '../../components/modal/modal.component';

import { AllColumns } from '../../interface/table';
import { AllColumnContainer, AllColumnNotaFiscal } from './consulta-individual-nf-container.template';
import { ConsultaIndivContainerResource } from './../../interface/consultaIndividual';
import { ConsultaIndivNfResource } from './../../interface/consultaIndividual';
import { Row } from '../../enum/row';


@Component({
  selector: 'app-consulta-individual-nf-container',
  templateUrl: './consulta-individual-nf-container.component.html',
  styleUrls: ['./consulta-individual-nf-container.component.css']
})
export class ConsultaIndividualNfContainerComponent implements OnInit {

  label: string;

  id: string;
  
  form: FormGroup;

  loaded = false;

  allColumnContainer: AllColumns[] = AllColumnContainer;

  allColumnNotaFiscal: AllColumns[] = AllColumnNotaFiscal;

  options = {
    label: ["Nota Fiscal", "Container"],
    value: 0
  };

  get valueInput(): FormControl { return this.form.get('valueInput') as FormControl; }

  constructor(
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private httpService: HttpService,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.form = this.formBuilder.group({
      valueInput: ['', [Validators.required]],
    });
  }

  onRadioChanged = () => {
    if (this.options.value === 0) {
      this.form.reset();
      this.label = "Nota Fiscal:";
      this.id = "notaFiscal";
    } else {
      this.form.reset();
      this.label = "Container:";
      this.id = "container";
    }
  }

  onSubmit() {
    this.loaded = true;

    if (this.options.value === 1) {
      this.httpService.getConsultaIndividual(this.form.value.valueInput).subscribe(
        (resposta: ConsultaIndivContainerResource[]) => {
          const res: ConsultaIndivContainerResource[] = resposta;
          if (res.length === 0) {
            this.loaded = false;
            this.toastr.info('Nenhum registro foi encontrado');
            return;
          }
          const table = {
            allColumns: this.allColumnContainer,
            element: res,
          };

          let obj = Object.keys(res[0])

          let objLabel = [
            "Container",
            "Data Ocorrência",
            "Quantidade Notas Fiscais",
            "Booking",
            "Etapa",
            "Armador"
          ]

          let list = []

          res.forEach((vl, index) => {
            if (index === 0) {
              for (let i = 0; i < obj.length; i++) {
                if (obj[i] === 'qtdNotas') {
                  list.push({
                    label: objLabel[i],
                    value: vl.qtdNotas,
                    format: Row.LINK,
                  });
                } else if (obj[i] !== 'dataOcorr' && obj[i] !== 'etapa') {
                  list.push({
                    label: objLabel[i],
                    value: vl[obj[i]],
                    format: Row.NULL,
                  });
                }
              }
            }
          });

          this.loaded = false;
          this.openModal(table, list);
          this.form.reset();
        },
        (error) => {
          this.loaded = false;
          this.toastr.error('Falha ao carregar os dados.');
          console.log("Erro", error);
        }
      );
    } else {
      this.httpService.getConsultaIndividual(this.form.value.valueInput).subscribe(
        (resposta: ConsultaIndivNfResource[]) => {
          const res: ConsultaIndivNfResource[] = resposta;
          if (res.length === 0) {
            this.loaded = false;
            this.toastr.info('Nenhum registro foi encontrado');
            return;
          }
          const table = {
            allColumns: this.allColumnNotaFiscal,
            element: res,
          };

          let obj = Object.keys(res[0])

          let objLabel = [
            "Número",
            "Remetente",
            "Etapa",
            "Data Ocorrência",
            "CTE",
            "Container",
            "Destinatário"
          ]

          let list = []

          res.forEach((vl, index) => {
            if (index === 0) {
              for (let i = 0; i < obj.length; i++) {
                if (obj[i] !== 'dataOcorr' && obj[i] !== 'etapa' && obj[i] !== 'destinatario') {
                  list.push({
                    label: objLabel[i],
                    value: vl[obj[i]],
                    format: Row.NULL,
                  });
                }
              }
            }
          });

          this.loaded = false;
          this.openModal(table, list);
          this.form.reset();
        },
        (error) => {
          this.loaded = false;
          this.toastr.error('Falha ao carregar os dados.');
          console.log("Erro", error);
        }
      );
    }
  }

  openModal(table: any, res: any) {
    this.dialog.open(ModalComponent, {
      width: '80%',
      height: '80%',
      data: {
        tableData: table,
        element: res,
        display: this.options.value === 0 ? 'notaFiscal' : 'container'
      }
    });
  }
}
