import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

import { isNullOrUndefined } from 'util';

import { ECharts } from 'echarts';
import * as echarts from 'echarts';

@Component({
  selector: 'app-chart-pie',
  templateUrl: './chart-pie.component.html',
  styleUrls: ['./chart-pie.component.css']
})
export class ChartPieComponent implements OnInit {

  @Input() dataPier: any[];

  @Output() posicao = new EventEmitter();

  options: any;

  constructor() { }

  ngOnInit(): void {
    this.options = {
      title: {
        text: 'Posição Atual dos Containers',
        left: 'center',
        textStyle: {
          fontSize: 20,
          color: '#fff'
        },
      },
      tooltip: {
        trigger: 'item', 
        formatter: '{d}%',
      },
      series: [
        {
          type: 'pie',
          radius: '55%',
          data: this.dataPier,
          label: {
            formatter: '{c}',
            position: 'inner',
            fontSize: 16, 
            rich: {
              per: {
                color: '#fff',
                display: 'none',
                fontWeight: 'bold'
              }
            }
          },
          color: ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc']
        },
        {
          type: 'pie',
          radius: ['55%', '55%'],
          data: this.dataPier,
          label: {
            normal: {
              textStyle: {
                fontSize: 17,
                color: '#fff',
              }
            }
          },
          labelLine: {
            normal: {
              lineStyle: {
                color: '#fff',
              }
            }
          },
        }
      ]
    };
  }

  ngAfterViewInit() {
    const myElement: HTMLDivElement = <HTMLDivElement>document.getElementById("pie");
    const echartsIntance: ECharts = echarts.init(myElement);

    if (isNullOrUndefined(echartsIntance)) {
      return;
    }

    echartsIntance.on("click", (params: any) => {
      this.posicao.emit(params.data.name)
    });
  }
}
