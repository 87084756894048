import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from 'src/app/service/auth.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  constructor(
    private router: Router,
    private authService: AuthService
  ) { }

  ngOnInit() {
  }

  appitems = [
    {
      label: 'DASHBOARD',
      link: '/dashboard',
      icon: 'dashboard',
    },
    {
      label: 'CONTROLE CARGA CONSOLIDADA',
      link: '/controle-carga-consolidada',
      icon: 'local_shipping',
    },
    {
      label: 'CONTAINERS DEPOSITADOS NO PORTO DE ORIGEM',
      link: '/containers-depositados-porto-origem',
      icon: 'navigation'
    },
    {
      label: 'CHEGADA DOS NAVIOS',
      link: '/chegada-navios',
      icon: 'directions_boat',
    },
    {
      label: 'CONTROLE DE CARGA NO COSTADO',
      icon: 'landscape',
      items: [
        {
          label: 'CONTAINERS NO PORTO DESTINO',
          link: '/controle-containers-porto-destino',
          icon: 'place',
        },
        {
          label: 'DEMURRAGE/ARMAZENAMENTO',
          link: '/controle-demurrage-armazenamento',
          icon: 'gite',
        },
        {
          label: 'LIBERAÇÃO DA CARGA',
          link: '/controle-liberacao-carga',
          icon: 'check_circle_outline',
        },
        {
          label: 'CARGA RETIDA',
          link: '/controle-carga-retida',
          icon: 'error_outline',
        }
      ]
    },
    {
      label: 'CONTROLE DESOVA/ARMAZÉM DE APOIO (COSTA/BEMOL)',
      icon: 'sync_alt',
      items: [
        {
          label: 'CONTAINERS DESOVA (COSTA/BEMOL)',
          link: '/controle-containers-desova',
          icon: 'code',
        },
        {
          label: 'CONTAINERS NO ARMAZÉM DE APOIO',
          link: '/controle-containers-armazem-apoio',
          icon: 'gite',
        },
      ]
    },
    {
      label: 'LEAD TIME',
      link: '/lead-time',
      icon: 'alarm',
    },
    {
      label: 'CONSULTA INDIVIDUAL POR NF OU CONTAINER',
      link: '/consulta-individual-nf-container',
      icon: 'content_paste',
    },
    {
      label: 'RELATÓRIOS',
      icon: 'text_snippet',
      items: [
        {
          label: 'TOTAL CUSTO EXTRA',
          link: '/total-custo-extra',
          icon: 'text_snippet',
        },
        {
          label: 'ARMAZENAGEM TERMINAL APOIO',
          link: '/terminal-apoio',
          icon: 'text_snippet',
        },
        {
          label: 'DEMURRAGE',
          link: '/demurrage',
          icon: 'text_snippet',
        },
        {
          label: 'ENTREGAS NO FERIADO',
          link: '/entregas-feriado',
          icon: 'text_snippet',
        },
        {
          label: 'ESTADIAS DE CARRETA',
          link: '/estadias-carreta',
          icon: 'text_snippet',
        },
        {
          label: 'HANDLING TERMINAL APOIO',
          link: '/handling-terminal-apoio',
          icon: 'text_snippet',
        },
        {
          label: 'PUXADA ADICIONAL TERMINAL APOIO',
          link: '/puxada-terminal-apoio',
          icon: 'text_snippet',
        },
      ]
    },
  ];


  config = {
    paddingAtStart: true,
    interfaceWithRoute: true,
    listBackgroundColor: 'rgb(30, 153, 209)',
    fontColor: 'rgb(255, 255, 255)',
    backgroundColor: 'rgb(30, 153, 209)',
    selectedListFontColor: '#00008B',
    highlightOnSelect: true,
    collapseOnSelect: true,
  };

  selectedItem() {
    this.router.navigate(['/dashboard']);
  }

  openMenu() {
    document.getElementById("menu").style.display = "block";
    document.getElementById("overlay").style.display = "block";
  }

  closeMenu() {
    document.getElementById("menu").style.display = "none";
    document.getElementById("overlay").style.display = "none";
  }

  goOut() {
    this.authService.logout();
    this.router.navigate(['/login']);
  }

}
