import { Injectable } from '@angular/core';

import { isNullOrUndefined } from 'util';

import { UserTokenResource } from '../interface/userTokenResource';

@Injectable({
    providedIn: 'root'
})
export class TokenService {

    constructor() { }

    setToken(key: string, value: any) {
        localStorage.setItem(key, value);
    }

    checkUser() {
        const user = JSON.parse(localStorage.getItem('usuario'));
        const name = user.usuario;

        return name;
    }

    removeToken(key: string) {
        localStorage.removeItem(key);
    }

    public checkToken(key: string) {
        let logado = false;
        const user = localStorage.getItem(key);

        if (user !== null && user !== undefined) {
            logado = true;
        }

        return logado;
    }

    flush() {
        localStorage.clear();
    }

    public getToken(key: string): string {
        const user: UserTokenResource = JSON.parse(localStorage.getItem(key));

        if (isNullOrUndefined(user)) {
            return undefined;
        }

        return user.token;
    }
}
