import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { ToastrService } from 'ngx-toastr';

import { AuthService } from 'src/app/service/auth.service';

import { PasswordRecoveryResource } from './../../interface/passwordRecoveryResource';

@Component({
  selector: 'app-recovery-password',
  templateUrl: './recovery-password.component.html',
  styleUrls: ['./recovery-password.component.css']
})
export class RecoveryPasswordComponent implements OnInit {

  passwordRecovery: PasswordRecoveryResource = <PasswordRecoveryResource>{}

  formRecovery: FormGroup;

  loaded: boolean = false;

  get email(): FormControl { return this.formRecovery.get('email') as FormControl; }

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    if (this.authService.isAuthenticated()) {
      this.router.navigate(['/dashboard']);
      return;
    }

    this.passwordRecovery.hostname = window.location.hostname;
    this.passwordRecovery.route = '/reset-password';

    this.formRecovery = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  submitForm() {
    this.loaded = true;
    this.passwordRecovery.email = this.email.value;
    this.authService.recoveryPassword(this.passwordRecovery).subscribe(
      _ => {
        this.toastr.success('Acesse sua caixa de email', 'E-mail enviado!');
        this.loaded = false;
      }, error => {
        console.log(error);
        this.toastr.error('Ou acesse sua caixa de e-mail', 'Verifique o e-mail digitado');
        this.loaded = false;
      }
    );
  }

  onBack() {
    this.router.navigate(['/login'])
  }
}
