import { Component, OnInit } from '@angular/core';

import { ToastrService } from "ngx-toastr";

import { HttpService } from "src/app/service/http.service";

import { AllColumns, FilterTables, TableData } from '../../../interface/table';
import { ContainerDesovaResource } from '../../../interface/controleDesovaArmazem';

import { AllColumnsDesova } from '../controle-desova-armazem-apoio.template';

import { Message } from '../../../enum/message';
import { Color } from '../../../enum/color';

@Component({
  selector: 'app-containers-desova',
  templateUrl: './containers-desova.component.html',
  styleUrls: ['./containers-desova.component.css']
})
export class ContainersDesovaComponent implements OnInit {

  tableData: TableData;

  allColumns: AllColumns[] = AllColumnsDesova;

  loaded: boolean = false;

  err: boolean = false;

  noRecords: boolean = false;

  message: Message;

  constructor(private httpService: HttpService, private toastr: ToastrService) { }

  ngOnInit() {
    this.getContainersDesova();
  }

  getContainersDesova() {

    this.httpService.getContainerDesova().subscribe(

      (resposta: ContainerDesovaResource[]) => {

        const res: ContainerDesovaResource[] = resposta;

        if (res.length === 0) {
          this.message = Message.NO_RECORDS;
          this.noRecords = true;
          this.loaded = true;
          return;
        }

        this.setColorsRow(res); 

        res.map((vl: ContainerDesovaResource) => {
          if (vl.diasFreetime < 0) {
            vl.diasFreetime = 0;
          }
        });

        const arrayFilter = res.map((vl: ContainerDesovaResource) => vl.etapa).filter((value, index, array) => { return array.indexOf(value) === index });

        let filterTables: FilterTables[] = [];

        arrayFilter.forEach((vl, index) => filterTables.push({ id: index + 1, name: vl, value: vl }));

        this.tableData = {
          allColumns: this.allColumns,
          filterTables: filterTables,
          element: res,
          field: "etapa"
        };
        this.loaded = true;
      },
      (error) => {
        console.log("Erro", error);
        this.toastr.error('Falha ao carregar os dados.');
        this.message = Message.ERROR;
        this.loaded = true;
        this.err = true;
      }
    );
  }

  setColorsRow(res: ContainerDesovaResource[]) {
    res.map((r: ContainerDesovaResource) => {
      if (r.diasFreetime > 2) {
        return r.colorRow = Color.RED;
      }
      switch (r.diasFreetime) {
        case 2:
          r.colorRow = Color.ORANGE;
          break;
        case 1:
          r.colorRow = Color.YELLOW;
          break;
        default:
          r.colorRow = Color.GREEN;
          break;
      }
    })
  }
}
