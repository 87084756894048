import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { HttpHeaders } from "@angular/common/http";

import { Observable } from "rxjs";
import { map } from 'rxjs/operators';

import { isNullOrUndefined } from "util";

import { JwtHelperService } from '@auth0/angular-jwt'

import { TokenService } from '../service/token.service';
import { HttpService } from './http.service';

import { User } from "../interface/user";
import { PasswordRecoveryResource } from './../interface/passwordRecoveryResource';
import { ChangePasswordResource } from './../interface/changePasswordResource';


@Injectable({
    providedIn: 'root'
})
export class AuthService {

    constructor(
        private router: Router,
        private jwtHelper: JwtHelperService,
        private tokenService: TokenService,
        private httpService: HttpService
    ) { }

    public logout(): void {
        this.httpService.setLoggedIn(false);
        this.httpService.flushToken();
        this.tokenService.removeToken('usuario');
        this.tokenService.flush();
        this.router.navigateByUrl('/login')
    }

    public authenticate(user: User): Observable<boolean> {
        return this.httpService.postUsuario(user).pipe(
            map(res => {
                if (res) {
                    this.httpService.setLoggedIn(true, res.token)
                    this.tokenService.setToken('usuario', JSON.stringify(res));
                    return true
                }
                return false;
            })
        );
    }

    public isAuthenticated(): boolean {
        const token: string = this.tokenService.getToken('usuario');

        if (isNullOrUndefined(token) || token.length === 0) {
            return false;
        }

        return !this.jwtHelper.isTokenExpired(token);
    }

    public recoveryPassword(passwordRecovery: PasswordRecoveryResource): Observable<any> {
        return this.httpService.postPasswordRecovery(passwordRecovery);
    }

    public resetPassword(newPassword: ChangePasswordResource): Observable<any> {
        return this.httpService.postPasswordReset(newPassword);
    }
}

