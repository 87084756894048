import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

import { isNullOrUndefined } from 'util';

import { ECharts } from 'echarts';
import * as echarts from 'echarts';



function autoFontSize() {
  let width = document.getElementById('chart').offsetWidth;
  let newFontSize;

  if(width <= 425) {
    newFontSize = Math.round(width / 50);
  } else {
    newFontSize = 12;
  }

  return newFontSize;
};

@Component({
  selector: 'app-chart-default',
  templateUrl: './chart-default.component.html',
  styleUrls: ['./chart-default.component.css']
})

export class ChartDefaultComponent implements OnInit {

  @Input() labelBar: string[];

  @Input() valueBar: number[];

  @Output() relatorio = new EventEmitter();

  @Input() texto: string;

  options: any;
  colors:Object[] = [];

  constructor() { }

  ngOnInit() {

    for(let i = 0; i < 200; i++)
    {
      this.colors.push({
        value: this.valueBar[i],
        itemStyle: { normal: { color: this.getRandomColor() } },
      });
    }
    this.options = {
      title: {
        text: this.texto,
        left: 'center',
        textStyle: {
          fontSize: 18,
          color: '#fff'
        },
      },
      xAxis: {
        type: 'category',
        data: this.labelBar,
        axisLabel: {
          inside: false,
          color: '#fff',
        },
        axisTick: {
          show: false,
        },
        axisLine: {
          show: false,
        },
        z: 10,
      },
      yAxis: {
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          textStyle: {
            fontSize: autoFontSize(),
            color: '#fff',
          },
        },
      },
      dataZoom: [
        {
          type: 'inside',
        },
      ],
      series: [{
        data: this.colors,
        type: 'bar',
        showBackground: true,
        label: {
          show: true,
          position: 'top',
          color: '#fff'
        },
        backgroundStyle: {
          color: '#fff'
        },
      }]
    }
  }

  ngAfterViewInit() {
    const myElement: HTMLDivElement = <HTMLDivElement>document.getElementById("bar");
    const echartsIntance: ECharts = echarts.init(myElement);

    if (isNullOrUndefined(echartsIntance)) {
      return;
    }

    echartsIntance.on("click", (params: any) => {
      this.relatorio.emit(params.name);
    });
  }

  letters = '0123456789ABCDEF';
  color = '#';

  getRandomColor() {
    this.color = '#';
    for (var i = 0; i < 6; i++) {
        this.color += this.letters[Math.floor(Math.random() * 16)];
    }
    if(this.color == "#1e99d1") return this.getRandomColor();
    return this.color;
  }
}
