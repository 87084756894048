import { Component, OnInit } from '@angular/core';

import { ToastrService } from 'ngx-toastr';

import { isNullOrUndefined } from 'util';

import { HttpService } from '../../service/http.service';

import { AllColumns, FilterTables, TableData } from './../../interface/table';
import { ChegadaNaviosResource } from './../../interface/chegadaNaviosResource';

import { AllColumn, FilterTable } from './chegada-navios.template';

import { Message } from '../../enum/message';
import { Color } from '../../enum/color';

@Component({
  selector: 'app-chegada-navios',
  templateUrl: './chegada-navios.component.html',
  styleUrls: ['./chegada-navios.component.css']
})
export class ChegadaNaviosComponent implements OnInit {

  tableData: TableData;

  tableDataContainer: TableData;

  allColumns: AllColumns[] = AllColumn;

  filterTables: FilterTables[] = FilterTable;

  loaded: boolean = false;

  err: boolean = false;

  noRecords: boolean = false;

  message: Message;

  constructor(private httpService: HttpService, private toastr: ToastrService) { }

  ngOnInit() {
    this.getChegadaNavios();
  }

  getChegadaNavios() {

    this.httpService.getChegadaNavios().subscribe(

      (resposta: ChegadaNaviosResource[]) => {

        const res: ChegadaNaviosResource[] = resposta;

        if (res.length === 0) {
          this.message = Message.NO_RECORDS;
          this.noRecords = true;
          this.loaded = true;
          return;
        }

        this.setColorsRow(res);

        this.tableData = {
          allColumns: this.allColumns,
          filterTables: this.filterTables,
          element: res,
          field: "etapa"
        };
        this.loaded = true;
      },
      (error) => {
        console.log("Erro", error);
        this.toastr.error('Falha ao carregar os dados.');
        this.message = Message.ERROR;
        this.loaded = true;
        this.err = true;
      }
    );
  }

  setColorsRow(res: ChegadaNaviosResource[]) {
    res.map((r: ChegadaNaviosResource) => {
      if (!isNullOrUndefined(r.dtPrevisaoChegada)) {
        let dt = r.dtPrevisaoChegada;
        let arrayFormat = dt.split('T');
        let arrayFormatDate = arrayFormat[0].split('-');
        let stringFormatada = arrayFormatDate[1] + '-' + arrayFormatDate[2] + '-' + arrayFormatDate[0];
        let dateColumn: Date = new Date(stringFormatada);
        let dateNow: Date = new Date();

        if (dateColumn.getTime() < dateNow.getTime()) {
          r.colorRow = Color.RED;
        }

      }
    });
  }
}
