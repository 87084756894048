import { AllColumns } from './../../interface/table';

import { Row } from './../../enum/row';

export const AllColumnContainer: AllColumns[] = [
    { nome: 'Etapa', display: 'etapa', index: 0, format: Row.NULL },
    { nome: 'Data Ocorrência', display: 'dataOcorr', index: 1, format: Row.DATE }
];

export const AllColumnNotaFiscal: AllColumns[] = [
    { nome: 'Etapa', display: 'etapa', index: 0, format: Row.NULL },
    { nome: 'Data Ocorrência', display: 'dataOcorr', index: 1, format: Row.DATE }
];
