import { NgModule } from '@angular/core';

import { NgxMaskModule } from 'ngx-mask';

import { LoginRoutingModule } from './login-routing.module';
import { SharedModule } from 'src/app/shared/shared.module';

import { MenuComponent } from './../../components/menu/menu.component';
import { TableComponent } from './../../components/table/table.component';
import { ModalComponent } from './../../components/modal/modal.component';
import { InputComponent } from './../../components/input/input.component';
import { RadioComponent } from './../../components/radio/radio.component';
import { ChartPieComponent } from './../../components/chart-pie/chart-pie.component';
import { ChartBarComponent } from './../../components/chart-bar/chart-bar.component';
import { ChartDefaultComponent } from './../../components/chart-default/chart-default.component';
import { SpinnerComponent } from './../../components/spinner/spinner.component';
import { MessageComponent } from '../../components/message/message.component';
import { SubCardComponent } from '../../components/sub-card/sub-card.component';
import { CardComponent } from '../../components/card/card.component';
import { TextBoxComponent } from './../../components/text-box/text-box.component';
import { FormatTextPipe } from '../../pipes/format-text.pipe';
import { ButtonComponent } from '../../components/button/button.component';
import { ChartsModule, ThemeService } from 'ng2-charts';

import { SatDatepickerModule, SatNativeDateModule, MAT_DATE_FORMATS, DateAdapter, MAT_DATE_LOCALE } from 'saturn-datepicker';
import {MatDatepickerModule, MatFormFieldModule, MatInputModule, MatNativeDateModule, MatButtonModule} from "@angular/material";
import { MomentDateAdapter } from '@angular/material-moment-adapter';

import { LoginComponent } from './login.component';
import { RecoveryPasswordComponent } from '../recovery-password/recovery-password.component';
import { ResetPasswordComponent } from './../reset-password/reset-password.component';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { ControleCargaConsolidadaComponent } from '../controle-carga-consolidada/controle-carga-consolidada.component';
import { ContainersDepositadosPortoOrigemComponent } from './../containers-depositados-porto-origem/containers-depositados-porto-origem.component';
import { ChegadaNaviosComponent } from './../chegada-navios/chegada-navios.component';
import { ControleContainersPortoDestinoComponent } from '../controle-carga-costado/controle-containers-porto-destino/controle-containers-porto-destino.component';
import { ControleDemurrageArmazenamentoComponent } from './../controle-carga-costado/controle-demurrage-armazenamento/controle-demurrage-armazenamento.component';
import { ControleLiberacaoCargaComponent } from './../controle-carga-costado/controle-liberacao-carga/controle-liberacao-carga.component';
import { ControleCargaRetidaComponent } from './../controle-carga-costado/controle-carga-retida/controle-carga-retida.component';
import { ContainersArmazemApoioComponent } from './../controle-desova-armazem-apoio/containers-armazem-apoio/containers-armazem-apoio.component';
import { ContainersDesovaComponent } from './../controle-desova-armazem-apoio/containers-desova/containers-desova.component';
import { LeadTimeComponent } from './../lead-time/lead-time.component';
import { ConsultaIndividualNfContainerComponent } from './../consulta-individual-nf-container/consulta-individual-nf-container.component';
import { NotFoundComponent } from './../not-found/not-found.component';
import { RelatorioComponent } from '../relatorio/relatorio.component';
import { TerminalApoioComponent } from '../terminal-apoio/terminal-apoio.component';
import { DemurrageComponent } from '../demurrage/demurrage.component';
import { EntregasFeriadoComponent } from '../entregas-feriado/entregas-feriado.component';
import { EstadiasCarretaComponent } from '../estadias-carreta/estadias-carreta.component';
import { HandlingTerminalApoioComponent } from '../handling-terminal-apoio/handling-terminal-apoio.component';
import { PuxadaTerminalApoioComponent } from '../puxada-terminal-apoio/puxada-terminal-apoio.component';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY'
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};

@NgModule({
    declarations:
        [
            MenuComponent,
            TableComponent,
            ModalComponent,
            RadioComponent,
            InputComponent,
            ChartPieComponent,
            ChartBarComponent,
            ChartDefaultComponent,
            MessageComponent,
            SpinnerComponent,
            CardComponent,
            SubCardComponent,
            TextBoxComponent,
            ButtonComponent,
            LoginComponent,
            RecoveryPasswordComponent,
            ResetPasswordComponent,
            DashboardComponent,
            ControleCargaConsolidadaComponent,
            ContainersDepositadosPortoOrigemComponent,
            ChegadaNaviosComponent,
            ControleContainersPortoDestinoComponent,
            ControleDemurrageArmazenamentoComponent,
            ControleLiberacaoCargaComponent,
            ControleCargaRetidaComponent,
            ContainersDesovaComponent,
            ContainersArmazemApoioComponent,
            LeadTimeComponent,
            ConsultaIndividualNfContainerComponent,
            NotFoundComponent,
            RelatorioComponent,
            FormatTextPipe,
            TerminalApoioComponent,
            DemurrageComponent,
            EntregasFeriadoComponent,
            EstadiasCarretaComponent,
            HandlingTerminalApoioComponent,
            PuxadaTerminalApoioComponent
        ],
    imports: [
        LoginRoutingModule,
        SharedModule,
        NgxMaskModule.forChild(),
        ChartsModule,
        MatDatepickerModule,
        MatNativeDateModule, MatFormFieldModule, MatInputModule,
        MatButtonModule, SatDatepickerModule, SatNativeDateModule
    ],
    exports: [LoginComponent],
    entryComponents: [ModalComponent],
    providers: [ThemeService,
      {
        provide: DateAdapter,
        useClass: MomentDateAdapter,
        deps: [MAT_DATE_LOCALE]
      },
      { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }]
})
export class LoginModule { }
