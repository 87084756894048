import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { ToastrService } from 'ngx-toastr';

import { AuthService } from '../../service/auth.service';

import { User } from './login.model';

type InputTypes = 'text' | 'password';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  formLogin: FormGroup

  loaded: boolean = false;

  isVisible: boolean = false;

  type: InputTypes = 'password';

  get email(): FormControl { return this.formLogin.get('email') as FormControl; }
  get senha(): FormControl { return this.formLogin.get('senha') as FormControl; }

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    if (this.authService.isAuthenticated()) {
      this.router.navigate(['/dashboard']);
      return;
    }

    this.formLogin = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      senha: ['', [Validators.required, Validators.minLength(6)]]
    });
  }

  submitForm() {
    this.loaded = true;
    const newUser = new User();

    newUser.email = this.email.value;
    newUser.senha = this.senha.value;

    this.authService.authenticate(newUser)
      .subscribe(
        res => {
          if (res) {
            this.toastr.success('Tracking Bemol', 'Seja bem vindo(a)');
            this.router.navigate(['/dashboard']);
            this.loaded = false;
          }
        }, error => {
          console.log('Erro no método submitForm: ', error);
          this.toastr.error('Usuário sem permissão.');
          this.loaded = false;
        }
      );
  }

  inputVisibility() {
    return this.isVisible ? 'text' : this.type;
  }

  changeVisibility(event: MouseEvent) {
    this.isVisible = !this.isVisible;
  }

}
