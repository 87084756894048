import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

import { isNullOrUndefined } from 'util';

import { ECharts } from 'echarts';
import * as echarts from 'echarts';

@Component({
  selector: 'app-chart-bar',
  templateUrl: './chart-bar.component.html',
  styleUrls: ['./chart-bar.component.css']
})
export class ChartBarComponent implements OnInit {

  @Input() labelBar: string[];

  @Input() valueBar: number[];

  @Output() previsao = new EventEmitter();

  @Input() texto: string;

  options: any;

  constructor() { }

  ngOnInit() {
    this.options = {
      title: {
        text: this.texto,
        left: 'center',
        textStyle: {
          fontSize: 20,
          color: '#fff'
        },
      },
      xAxis: {
        type: 'category',
        data: this.labelBar,
        axisLabel: {
          inside: false,
          color: '#fff',
        },
        axisTick: {
          show: false,
        },
        axisLine: {
          show: false,
        },
        z: 10,
      },
      yAxis: {
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          textStyle: {
            color: '#fff',
          },
        },
      },
      dataZoom: [
        {
          type: 'inside',
        },
      ],
      series: [{
        data: [
          {
            value: this.valueBar[0],
            itemStyle: { normal: { color: '#ee6666' } },
          },
          {
            value: this.valueBar[1],
            itemStyle: { normal: { color: '#ffa500'} },
          },
          {
            value: this.valueBar[2],
            itemStyle: { normal: { color: '#fac858' } },
          },
          {
            value: this.valueBar[3],
            itemStyle: { normal: { color: '#5470c6' } },
          },
          {
            value: this.valueBar[4],
            itemStyle: { normal: { color: '#3ba272'} },
          },
          {
            value: this.valueBar[5],
            itemStyle: { normal: { color: '#91cc75'  } },
          },
        ],
        type: 'bar',
        showBackground: true,
        label: {
          show: true,
          position: 'top',
          color: '#fff'
        },
        backgroundStyle: {
          color: '#fff'
        },
      }]
    }
  }

  ngAfterViewInit() {
    const myElement: HTMLDivElement = <HTMLDivElement>document.getElementById("bar");
    const echartsIntance: ECharts = echarts.init(myElement);

    if (isNullOrUndefined(echartsIntance)) {
      return;
    }

    echartsIntance.on("click", (params: any) => {
      this.previsao.emit(params.data)
    });
  }
}
