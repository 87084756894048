import { Component, OnInit } from '@angular/core';
import { RelatorioResource } from 'src/app/interface/RelatorioResouce';
import { HttpService } from '../../service/http.service';
import { Message } from '../../enum/message';
import { ToastrService } from 'ngx-toastr';
import { Moment } from 'moment';
import Swal from 'sweetalert2';
import { RelatorioGeralResource } from 'src/app/interface/RelatorioGeralResource';

@Component({
  selector: 'app-handling-terminal-apoio',
  templateUrl: './handling-terminal-apoio.component.html',
  styleUrls: ['./handling-terminal-apoio.component.css']
})
export class HandlingTerminalApoioComponent implements OnInit {

  loaded: boolean = false;

  err: boolean = false;

  noRecords: boolean = false;

  message: Message;

  filterDate: { begin: Moment, end: Moment};

  constructor(private httpService: HttpService, private toastr: ToastrService) { }

  titulo: string = "Handling Terminal de Apoio";

  labelBar: string[] = [];

  valueBar: number[] = [];

  ngOnInit() {
    this.getRelatorio("", "");
  }

  getRelatorio(begin: string, end: string) {
    this.httpService.getHandlingTerminalApoio(begin, end).subscribe(
      (resposta: RelatorioResource[]) => {
        const res: RelatorioResource[] = resposta;

        if (res.length === 0) {
          this.message = Message.NO_RECORDS;
          this.noRecords = true;
          this.loaded = true;
          return;
        }
        var barChartLabels: string[] = [];
        var barChartData: number[] = [];

        res.map((resultado) => {
          barChartLabels.push(resultado.data);
          barChartData.push(Number.parseFloat(resultado.valorExtra.toFixed(2)));
        });

        this.labelBar = barChartLabels;
        this.valueBar = barChartData;

        this.loaded = true;
      },
      (error) => {
        console.log("Erro", error);
        this.toastr.error('Falha ao carregar os dados.');
        this.message = Message.ERROR;
        this.loaded = true;
        this.err = true;
      }
    );
  }

  filtrar()
  {
    this.loaded = false;
    this.getRelatorio(this.filterDate.begin.format('YYYY-MM-DD'), this.filterDate.end.format('YYYY-MM-DD'));
  }

  onRelatorio(relatorio: string) {
    this.httpService.getRelatorioDetalhes(relatorio, this.titulo).subscribe(
      (resposta: RelatorioGeralResource[]) => {

        var html = "<div class='table-responsive'><table>";
        html += "<tr><th>CAB</th><th>CONTAINER</th><th>SERVIÇO</th><th>VALOR</th><th>DATA</th></tr>";
        resposta.forEach(function(relatorio, index) {
          var dataFim = new Date(Date.parse(relatorio.dataFim));
          html += "<tr>";
          html += "<td>" + relatorio.cab + "</td>"
          html += "<td>" + relatorio.container + "</td>"
          html += "<td>" + relatorio.servico + "</td>"
          html += "<td>R$" + relatorio.valorExtra.toFixed(2).replace('.', ',') + "</td>"
          html += "<td>" + dataFim.toLocaleDateString() + "</td>"
          html += "</tr>";
        });

        html+= "</table></div>";

        Swal.fire({
          title: 'Detalhes por serviço em ' + relatorio,
          html: html,
          icon: "info",
          showCloseButton: true,
          showConfirmButton: false,
          width: 1200
        })
      });

  }

  exportar()
  {
    if(this.filterDate != undefined)
    {
      this.httpService.getFileRelatorioHandlingTerminalApoio(this.filterDate.begin.format('YYYY-MM-DD'), this.filterDate.end.format('YYYY-MM-DD')).subscribe((resposta: any) =>
      {
        this.onBlob(resposta);
      })
    }
    else
    {
      this.httpService.getFileRelatorioHandlingTerminalApoio("", "").subscribe((resposta: any) =>
      {
        this.onBlob(resposta);

      })
    }
  }

  onBlob(resposta) {
    const blob = new Blob([resposta], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }); // you can change the type
    const fileName: string =  this.titulo + '.xlsx';
    const objectUrl: string = URL.createObjectURL(blob);
    const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;

    a.href = objectUrl;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
    URL.revokeObjectURL(objectUrl);
  }

}
