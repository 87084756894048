import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { isNullOrUndefined } from 'util';

import { ToastrService } from 'ngx-toastr';

import { HttpService } from '../../service/http.service';

import { PosicaoContainerResource, PrevisaoChegadaResource, DataChart } from './../../interface/graficoResource';

import { Message } from '../../enum/message';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  constructor(
    private httpService: HttpService,
    private router: Router,
    private toastr: ToastrService
  ) { }

  dataPier: DataChart[] = [];

  labelBar: string[] = [];

  valueBar: number[] = [];

  titulo: string = 'Previsão de Chegada dos Containers';

  loadedResPosicao: boolean = false;
  loadedResPrevisao: boolean = false;

  err: boolean = false;

  noRecordsResPrevisao: boolean = false;
  noRecordsResPosicao: boolean = false;

  message: Message;

  ngOnInit() {
    this.getDataChart();
    setInterval(() => {
      this.getDataChart();
    }, (3600000 * 3)); //Atualiza os dados do gráfico a cada 3 horas
  }

  getDataChart(): void {

    this.httpService.getDataChart().subscribe(

      (resposta) => {

        var posicaoContainers = this.setPosicao(resposta.posicaoContainers);
        var previsaoChegada = this.setPrevisao(resposta.previsaoChegada);
        if(posicaoContainers == "vazio" && posicaoContainers == "vazio") this.message = Message.NO_RECORDS;
        if(posicaoContainers == "erro" && previsaoChegada == "erro") this.message = Message.ERROR;
      },
      (error) => {
        console.log("Erro", error);
        this.toastr.error('Falha ao carregar os dados.');
        this.message = Message.ERROR;
        this.loadedResPosicao = true;
        this.loadedResPrevisao = true;
        this.err = true;
      }
    );
  }

  onPosicao(posicao: string) {
    switch (posicao) {
      case 'Porto de Origem':
        this.router.navigate(['/containers-depositados-porto-origem']);
        break;
      case 'Navio':
        this.router.navigate(['/chegada-navios']);
        break;
      case 'Porto de Destino':
        this.router.navigate(['/controle-containers-porto-destino']);
        break;
      case 'Terminal de Apoio':
        this.router.navigate(['/controle-containers-armazem-apoio']);
        break;
      default:
        this.router.navigate(['/dashboard']);
        break;
    }
  }

  onPrevisao(previsao: string) {
    if (previsao) {
      this.router.navigate(['/chegada-navios']);
    }
  }

  setPrevisao(resPrevisao: PrevisaoChegadaResource): string {
    try
    {
      if (isNullOrUndefined(resPrevisao) || (resPrevisao.em1Dia == 0 && resPrevisao.em2Dia == 0 && resPrevisao.em3Dia == 0 && resPrevisao.em4Dia == 0 && resPrevisao.em5Dia == 0 && resPrevisao.maisDe5Dia == 0)) {
        this.noRecordsResPrevisao = true;
        this.loadedResPrevisao = true;
        return "vazio";
      }

      let labelPrevisao: string[];
      let valuePrevisao: number[];

      labelPrevisao = ['Em 1 dia', 'Em 2 dias', 'Em 3 dias', 'Em 4 dias', 'Em 5 dias', '> 5 dias'];

      valuePrevisao = [
        resPrevisao.em1Dia,
        resPrevisao.em2Dia,
        resPrevisao.em3Dia,
        resPrevisao.em4Dia,
        resPrevisao.em5Dia,
        resPrevisao.maisDe5Dia
      ];

      this.labelBar = labelPrevisao;
      this.valueBar = valuePrevisao;

      this.loadedResPrevisao = true;
      return "";
    }
    catch(Error)
    {
      return "erro";
    }

  }

  setPosicao(resPosicao: PosicaoContainerResource): string {
    try
    {
      console.log(resPosicao);
      if (isNullOrUndefined(resPosicao) || (resPosicao.nosNavios == null && resPosicao.portoDestino == null && resPosicao.portoOrigem == null && resPosicao.terminalApoio == null)) {
        this.noRecordsResPosicao = true;
        this.loadedResPosicao = true;
        return "vazio";
      }

      let dataPosicao: DataChart[] = [];
      let valuePosicao: DataChart[] = [];

      dataPosicao = [
        { value: resPosicao.portoOrigem, name: 'Porto de Origem' },
        { value: resPosicao.nosNavios, name: 'Navio' },
        { value: resPosicao.portoDestino, name: 'Porto de Destino' },
        { value: resPosicao.terminalApoio, name: 'Terminal de Apoio' }
      ];

      dataPosicao.forEach((element) => {
        if (element.value !== 0) {
          valuePosicao.push({
            value: element.value,
            name: element.name
          })
        }
      })

      this.dataPier = valuePosicao;
      this.loadedResPosicao = true;
      return "";
    }
    catch(Error)
    {
      return "erro";
    }

  }
}
