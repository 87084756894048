import { AllColumns } from "../../interface/table";

import { Row } from "src/app/enum/row";


export const AllColumn: AllColumns[] = [
    { nome: 'Container', display: 'container', index: 0, format: Row.NULL },
    { nome: 'Qtde de NFs', display: 'qtdNotas', index: 1, format: Row.LINK },
    { nome: 'Qtde Remetente', display: 'qtdRemetente', index: 2, format: Row.LINK },
    { nome: 'Porto de Origem', display: 'origem', index: 3, format: Row.NULL },
    { nome: 'Navio', display: 'navio', index: 4, format: Row.NULL },
    { nome: 'Booking', display: 'booking', index: 5, format: Row.NULL }, 
    { nome: 'Data Coleta', display: 'dtColeta', index: 6, format: Row.DATE }, 
];