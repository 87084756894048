import { Component, Input, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatDialog } from '@angular/material';

import { CdkDragStart, CdkDropList, moveItemInArray } from "@angular/cdk/drag-drop";

import { AllColumns, TableData } from 'src/app/interface/table';
import { FilterTables } from './../../interface/table';
import { TableService } from 'src/app/service/table.service';
import { TableUtil } from './table.component.util';
import { Row } from 'src/app/enum/row';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.css']
})
export class TableComponent implements OnInit, AfterViewInit {

  @Input() tableData: TableData;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  dataSource: MatTableDataSource<any>;

  item: FilterTables[];

  columnsShow: any;

  previousIndex: number;

  displayedColumns: string[] = [];

  data: any[] = [];

  date: string;

  loaded: boolean = false;

  constructor(public dialog: MatDialog, private tableService: TableService) { }

  ngOnInit() {
    this.initializeTable();
  }

  ngAfterViewInit() {
    this.data = this.dataSource.data;
    this.dataSource.sort = this.sort;
  }

  initializeTable() {
    this.dataSource = new MatTableDataSource(this.tableData.element);
    this.item = this.tableData.filterTables;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.columnsShow = this.tableData.allColumns;
    this.paginator._intl.itemsPerPageLabel = "Itens por página";
    this.paginator._intl.firstPageLabel = "Primeira página";
    this.paginator._intl.lastPageLabel = "Última página";
    this.paginator._intl.nextPageLabel = "Próxima página";
    this.paginator._intl.previousPageLabel = "Página anterior";
    this.paginator._intl.getRangeLabel = (
      page: number,
      pageSize: number,
      length: number
    ) => {
      return (
        page * pageSize +
        1 +
        " - " +
        (page * pageSize + pageSize) +
        " de " +
        length
      );
    };
    this.tableService.loadedModal.subscribe(
      (res: boolean) => {
        this.loaded = res;
      },
    )
    this.setDisplayColumns();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;

    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  applyFilterSelect(property: FilterTables[]) {
    const itemsChecked = property.map((vl: FilterTables) => vl.value);
    this.dataSource.data = this.data.filter((d: any) => {
      let propertyValue = d[this.tableData.field];
      if (itemsChecked.indexOf(propertyValue.toString()) !== -1) {
        return d;
      }
    });
  }

  dragStarted(_: CdkDragStart, index: number) {
    this.previousIndex = index;
  }

  dropListDropped(event: CdkDropList, index: number) {
    if (event) {
      moveItemInArray(this.columnsShow, this.previousIndex, index);
      this.columnsShow = [...this.columnsShow];
    }
  }

  setDisplayColumns() {
    this.displayedColumns = this.columnsShow.map((item) => {
      return item["display"];
    });
  }

  exportTable() {
    const dataOriginal: any[] = this.dataSource.filter ? this.dataSource.filteredData : this.dataSource.data;
    const dataCopy = <any[]>JSON.parse(JSON.stringify(dataOriginal));
    const allColumnsCopy = <AllColumns[]>JSON.parse(JSON.stringify(this.tableData.allColumns));
    const fieldsColumns: string[] = [];
    const exportData = [];

    //Coverte data p/ exportação em XLSX.
    allColumnsCopy.forEach((data: AllColumns) => {
      dataCopy.map((element: any) => {
        if (data.format === Row.DATE) {
          if (element[data.display]) {
            let dt = element[data.display];
            let arrayFormat = dt.split('T');
            let arrayFormatDate = arrayFormat[0].split('-');
            let stringFormatada = arrayFormatDate[1] + '-' + arrayFormatDate[2] + '-' + arrayFormatDate[0];
            let dateColumn: Date = new Date(stringFormatada);
            element[data.display] = new Date(dateColumn.setDate(dateColumn.getDate() + 1));
          }
        }
      });
    });

    //Cria um array de string somente com as chaves da tabela.
    this.tableData.allColumns.forEach((data: any) => {
      fieldsColumns.push(data.display)
    })

    //Deleta do array as propriedades que não são mostradas em tela.
    dataCopy.map((element: any) => {
      Object.keys(element).forEach(field => {
        if (!(fieldsColumns.indexOf(field) !== -1)) {
          delete element[field]
        }
      });
    })

    //Cria um novo array para exportação em XLSX, trocando o nome da propriedade e adicionando upperCase.
    dataCopy.forEach(column => {
      let instance = Object.assign({});
      Object.keys(column).forEach(field => {
        let exactColumn: any = this.tableData.allColumns.find(collumns => collumns.display === field);
        let property: string = exactColumn.nome.toUpperCase();
        let value: any = exactColumn.display;
        instance[property] = column[value];
      });
      exportData.push(instance);
    });

    //Passa para a função o array que será transformado em XLSX.
    TableUtil.exportToExcel(exportData);
  }

  openModal(element: any, display: string): void {
    element.field = display
    switch (display) {
      case 'qtdContainer':
        this.tableService.getContainersNavios(element);
        break;
      case 'qtdNotas':
        this.tableService.getNotaFiscalContainers(element);
        break;
      case 'qtdRemetente':
        this.tableService.getRemetenteContainers(element);
        break;
      default:
        console.log('Erro');
        break;
    }
  }
}

