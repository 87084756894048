import { Component, Input, OnInit } from '@angular/core';
import { TableService } from 'src/app/service/table.service';

import { Row } from '../../enum/row';

@Component({
  selector: 'app-text-box',
  templateUrl: './text-box.component.html',
  styleUrls: ['./text-box.component.css']
})
export class TextBoxComponent implements OnInit {

  @Input() title = '';
  @Input() options: [{ label: string, value: string, format: Row }] = undefined;

  loaded: boolean = false;

  constructor(private tableService: TableService) { }

  ngOnInit() {
    this.tableService.loadedModal.subscribe(
      (res: boolean) => {
        this.loaded = res;
      },
    )
  }

  openModal(element: any) {
    let list: { field: string, container: string } = undefined;
    let container: string;

    element.forEach(element => {
      if (element.label === "Container") {
        container = element.value;
      }
    });

    list = { field: "qtdNotas", container: container }

    this.tableService.getNotaFiscalContainers(list);
  }

}
